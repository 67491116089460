<template>
  <div class="log-section">
    <div class="header">
      <h3>Logs</h3>
    </div>

    <div v-if="fetching" class="text-center">
      <v-progress-linear
        color="primary accent-4"
        indeterminate
        height="5"
      ></v-progress-linear>
    </div>

    <!-- Comment List -->
    <div class="log-items mt-6">
      <div v-for="(log, index) in logs" :key="index" class="log-item">
        <p>
          <span class="log-line" v-html="logFormat(log)"></span>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import { dateFormat } from '@/utils/date'

export default {
  mixins: [SnackbarMixin, ErrorHandlerMixin],
  props: {
    preFetch: {
      type: Boolean,
      default: true,
    },
    maintenanceId: {
      required: true,
      type: [String, Number],
    },
  },

  created() {
    if (this.preFetch) {
      this.clearLogs()
      this.fetch()
    }
  },

  data() {
    return {
      options: {},
      fetching: false,
    }
  },

  computed: {
    ...mapState({
      logs: (state) => state.maintenance.logs,
    }),
  },

  methods: {
    dateFormat,
    ...mapActions({
      getLogs: 'maintenance/getLogs',
    }),
    ...mapMutations({
      clearLogs: 'maintenance/clearLogs',
    }),

    logFormat(item) {
      const userName = item?.user?.full_name || 'Unknown user'
      const supplierName = item?.supplier?.company_name || 'Unknown supplier'
      return item.template
        .replace(
          ':full_name',
          `<span style="color:#376fdb;">${userName}</span>`
        )
        .replace(
          ':supplier',
          `<span style="color:#376fdb;">${supplierName}</span>`
        )
        .replace(
          ':date_time',
          `<span style="font-weight:normal;">${dateFormat(
            item.created_at,
            'DD MMM hh:mm'
          )}</span>`
        )
    },

    async fetch() {
      if (this.fetching) return

      this.fetching = true

      let params = {
        maintenanceId: this.maintenanceId,
        sort: '-created_at',
        pagination: false,
      }

      await this.getLogs(params).finally(() => {
        this.fetching = false
      })
    },
  },
}
</script>
<style scoped>
.log-section {
  width: 100%;
  padding: 10px;
}

.log-line {
  font-weight: bold;
}

.log-items {
  min-height: 100px;
  max-height: 300px;
  overflow: scroll;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #000;
  padding-bottom: 10px;
}
</style>
